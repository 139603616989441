import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Sell My Car Online | Buy My Car" description="Do you want to sell your car quickly? We come to you offering the best prices and hassle free transaction! Get in touch today." />

    <Container className="my-5 page-404">
      <Row>
        <Col lg={8}>
          <h1 className="page-404-title">Page not found</h1>
          <p>Sorry, the page you are looking for was not found. If you need help, please contact us using the form below.</p>
          <hr></hr>
          <Row>
            <Col sm={6}>
              <Row>
                <Col sm={12}>
                  <h6>iCashCars.co.uk</h6>
                  <ul className="list-unstyled">
                    <li>
                      <a href="tel:0800 2884966"><svg className="svg-inline--fa fa-phone fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>0800 2884966</a></li>
                    <li>
                      <a href="mailto:hello@icashcars.co.uk"><svg className="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>hello@icashcars.co.uk</a>
                      </li>
                    </ul>
                  </Col>
                <Col sm={12}>
                  <p className="justify-content-center justify-content-sm-start d-sm-flex mt-3"><svg className="svg-inline--fa fa-map-marker fa-w-12" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="map-marker" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>
                    Unit 1, Pinecopse<br/>
                    Nine Mile Ride<br/>
                    Wokingham<br/>
                    Berkshire<br/>
                    RG40 3ND
                  </p>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <div className="embed-responsive embed-responsive-16by9">
              <iframe title="Robots" className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.742489127093!2d-0.5690466838690559!3d51.6096067796521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487668a7b7f6e977%3A0x106255ea483c5597!2sGrove+Hill%2C+Chalfont+St+Peter%2C+Gerrards+Cross+SL9+9PD%2C+UK!5e0!3m2!1sen!2sin!4v1517668404520" style={{transform:'scale(0.77)'}}></iframe>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={4} className="send-box">
          <h4 className="mt-5 mt-sm-0">Send us a message</h4>
          <form className="form" action="/" method="post">
              <div className="form-group mb-2">
                <input type="text" className="form-control" name="name" placeholder="Name &amp; Surname" />
              </div>
              <div className="form-group mb-2">
                <input type="email" className="form-control" name="email" placeholder="Email address" />
              </div>
              <div className="form-group mb-2">
                <input type="text" className="form-control" name="phone" placeholder="Phone number" />
              </div>
              <div className="form-group mb-2">
                <textarea className="form-control" name="message" rows="3" placeholder="Message"></textarea>
              </div>
              <div className="form-group mb-2">
                <div className="g-recaptcha" data-sitekey="6LdCR3sUAAAAAFexK0tXbXIzV27nsJCwj2W7f35k">
                  <div>
                    <div>
                      <iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdCR3sUAAAAAFexK0tXbXIzV27nsJCwj2W7f35k&amp;co=aHR0cHM6Ly9jcm0uaWNhc2hjYXJzLmNvLnVrOjQ0Mw..&amp;hl=ru&amp;v=dpzVjBAupwRfx3UzvXRnnAKb&amp;size=normal&amp;cb=b9xe9eiq05vx" width="304" height="78" role="presentation" name="a-eg5h3vbqfd2j" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox">
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div className="submit-button text-right ">
                <input type="submit" className="btn d-block py-1 ml-auto" value="Submit" />
              </div>
            </form>
        </Col>
      </Row>
    </Container>

    <div className="page-404-base-text">

    </div>

  </Layout>
)

export default NotFoundPage
